import React, { useState } from 'react';
import { Plus, Trash2, GripVertical, X, Check, Pencil } from 'lucide-react';
import { useCustomFieldsStore } from '../../store/customFieldsStore';
import type { CustomFieldType, CustomFieldDisplayLocation } from '../../types/customField';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableFieldItemProps {
  id: string;
  name: string;
  type: CustomFieldType;
  required: boolean;
  displayLocation: CustomFieldDisplayLocation;
  options?: string[];
  onDelete: (id: string) => void;
  onEdit: (id: string, updates: { name: string; type: CustomFieldType; required: boolean; displayLocation: CustomFieldDisplayLocation; options?: string[] }) => void;
}

const SortableFieldItem = ({ id, name, type, required, displayLocation, options, onDelete, onEdit }: SortableFieldItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedField, setEditedField] = useState({ name, type, required, displayLocation, options: options?.join(', ') || '' });
  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSave = () => {
    const updatedOptions = editedField.type === 'select' 
      ? editedField.options.split(',').map(o => o.trim()).filter(o => o.length > 0)
      : undefined;
      
    onEdit(id, {
      name: editedField.name,
      type: editedField.type as CustomFieldType,
      required: editedField.required,
      displayLocation: editedField.displayLocation || 'general',
      options: updatedOptions,
    });
    setIsEditing(false);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex items-center gap-4 p-4 bg-white border rounded-md mb-2 group"
    >
      <div {...attributes} {...listeners} className="cursor-grab">
        <GripVertical className="w-5 h-5 text-gray-400" />
      </div>
      
      {isEditing ? (
        <div className="flex-1 space-y-2">
          <input
            type="text"
            value={editedField.name}
            onChange={(e) => setEditedField(prev => ({ ...prev, name: e.target.value }))}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          <div className="flex gap-4">
            <select
              value={editedField.type}
              onChange={(e) => setEditedField(prev => ({ ...prev, type: e.target.value as CustomFieldType }))}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
              <option value="select">Select</option>
              <option value="url">URL</option>
            </select>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={editedField.required}
                onChange={(e) => setEditedField(prev => ({ ...prev, required: e.target.checked }))}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              Required
            </label>
            <select
              value={editedField.displayLocation}
              onChange={(e) => setEditedField(prev => ({ ...prev, displayLocation: e.target.value as CustomFieldDisplayLocation }))}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="general">General</option>
              <option value="company">Company Information</option>
              <option value="contact">Contact Information</option>
              <option value="deal">Deal Information</option>
            </select>
          </div>
          {editedField.type === 'select' && (
            <input
              type="text"
              value={editedField.options}
              onChange={(e) => setEditedField(prev => ({ ...prev, options: e.target.value }))}
              placeholder="Option 1, Option 2, Option 3"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          )}
          <div className="flex gap-2">
            <button
              onClick={handleSave}
              className="inline-flex items-center px-2 py-1 text-sm text-white bg-blue-600 rounded hover:bg-blue-700"
            >
              <Check className="w-4 h-4 mr-1" />
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="inline-flex items-center px-2 py-1 text-sm text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
            >
              <X className="w-4 h-4 mr-1" />
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex-1">
            <div className="font-medium">{name}</div>
            <div className="text-sm text-gray-500">
              {type.charAt(0).toUpperCase() + type.slice(1)}
              {required && ' • Required'}
              {options && ` • Options: ${options.join(', ')}`}
              {displayLocation && ` • Display Location: ${displayLocation}`}
            </div>
          </div>
          <div className="flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={() => setIsEditing(true)}
              className="p-1 text-gray-500 hover:text-gray-700 rounded"
            >
              <Pencil className="w-4 h-4" />
            </button>
            <button
              onClick={() => onDelete(id)}
              className="p-1 text-red-500 hover:text-red-700 rounded"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export const CustomFieldsManager = () => {
  const { fields, addField, deleteField, updateField, reorderFields, loading, error } = useCustomFieldsStore();
  const [isAddingField, setIsAddingField] = useState(false);
  const [newField, setNewField] = useState({
    name: '',
    type: 'text' as CustomFieldType,
    required: false,
    displayLocation: 'general' as CustomFieldDisplayLocation,
    options: '',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);
      
      const newOrder = arrayMove(fields, oldIndex, newIndex);
      reorderFields(newOrder.map(field => field.id));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!newField.name.trim()) {
      alert('Field name is required');
      return;
    }

    try {
      const options = newField.type === 'select' 
        ? newField.options
            .split(',')
            .map(o => o.trim())
            .filter(o => o.length > 0)
        : undefined;

      await addField({
        name: newField.name,
        type: newField.type,
        required: newField.required,
        displayLocation: newField.displayLocation,
        options: options,
      });

      setNewField({ name: '', type: 'text', required: false, displayLocation: 'general', options: '' });
      setIsAddingField(false);
    } catch (err: any) {
      console.error('Error adding custom field:', err);
      alert(err.message || 'Failed to add custom field. Please try again.');
    }
  };

  const handleDeleteField = (id: string) => {
    const field = fields.find(f => f.id === id);
    if (!field) return;

    const confirmDelete = window.confirm(
      `Are you sure you want to delete the field "${field.name}"?\n\nWarning: All data captured in this field across all deals will be permanently lost.`
    );

    if (confirmDelete) {
      deleteField(id);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => setIsAddingField(true)}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Field
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
          {error}
        </div>
      )}

      {isAddingField && (
        <form onSubmit={handleSubmit} className="mb-6 p-4 border rounded-md">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Field Name
              </label>
              <input
                type="text"
                required
                value={newField.name}
                onChange={(e) => setNewField(prev => ({ ...prev, name: e.target.value }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Field Type
              </label>
              <select
                value={newField.type}
                onChange={(e) => setNewField(prev => ({ 
                  ...prev, 
                  type: e.target.value as CustomFieldType,
                  options: e.target.value === 'select' ? '' : ''
                }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="select">Select</option>
                <option value="url">URL</option>
              </select>
            </div>

            {newField.type === 'select' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Options (comma-separated)
                </label>
                <input
                  type="text"
                  value={newField.options}
                  onChange={(e) => setNewField(prev => ({ ...prev, options: e.target.value }))}
                  placeholder="Option 1, Option 2, Option 3"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Display Location
              </label>
              <select
                value={newField.displayLocation}
                onChange={(e) => setNewField(prev => ({ 
                  ...prev, 
                  displayLocation: e.target.value as CustomFieldDisplayLocation 
                }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="general">General</option>
                <option value="company">Company Information</option>
                <option value="contact">Contact Information</option>
                <option value="deal">Deal Information</option>
              </select>
            </div>

            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={newField.required}
                  onChange={(e) => setNewField(prev => ({ ...prev, required: e.target.checked }))}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm font-medium text-gray-700">Required</span>
              </label>
            </div>

            <div className="flex gap-2">
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
              >
                Add Field
              </button>
              <button
                type="button"
                onClick={() => setIsAddingField(false)}
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={fields.map(field => field.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-2">
            {fields.map((field) => (
              <SortableFieldItem
                key={field.id}
                id={field.id}
                name={field.name}
                type={field.type}
                required={field.required}
                displayLocation={field.displayLocation}
                options={field.options}
                onDelete={handleDeleteField}
                onEdit={updateField}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};