import React from 'react';
import { ListPlus } from 'lucide-react';
import { CustomFieldsManager } from './CustomFieldsManager';

export const CustomFieldsSettings = () => {
  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center gap-2">
          <ListPlus className="w-6 h-6 text-gray-400" />
          <h2 className="text-xl font-semibold text-gray-900">Custom Fields</h2>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Add custom fields to track additional information for each deal
        </p>
      </div>
      <div className="p-6">
        <CustomFieldsManager />
      </div>
    </div>
  );
};