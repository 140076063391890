import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, query, where, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { DealActivity } from '../../types/deal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuthStore } from '../../store/authStore';
import { Plus, Trash2, Check, Edit2 } from 'lucide-react';
import { Button } from '../../components/ui/Button';

interface ActivitiesProps {
  dealId: string;
}

export const Activities: React.FC<ActivitiesProps> = ({ dealId }) => {
  const { user } = useAuthStore();
  const [activities, setActivities] = useState<DealActivity[]>([]);
  const [newActivity, setNewActivity] = useState<DealActivity>({
    title: '',
    description: '',
    actionedDate: new Date().toISOString(),
    status: 'Completed'
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingActivity, setEditingActivity] = useState<DealActivity | null>(null);

  useEffect(() => {
    if (user?.organizationId) {
      fetchActivities();
    }
  }, [dealId, user?.organizationId]);

  const fetchActivities = async () => {
    if (!user?.organizationId || !dealId) {
      console.error('Missing required fields for fetch:', { organizationId: user?.organizationId, dealId });
      return;
    }

    try {
      console.log('Fetching activities for deal:', dealId);
      const activitiesRef = collection(db, 'deals', dealId, 'activities');
      const q = query(activitiesRef);
      const querySnapshot = await getDocs(q);
      
      const fetchedActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as DealActivity));

      console.log('Fetched activities:', fetchedActivities);
      setActivities(fetchedActivities.sort((a, b) => 
        new Date(b.actionedDate).getTime() - new Date(a.actionedDate).getTime()
      ));
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleAddActivity = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log('Adding activity:', newActivity);
      
      if (!user?.organizationId || !dealId) {
        console.error('Missing required fields:', { organizationId: user?.organizationId, dealId });
        return;
      }

      const activityToAdd = {
        ...newActivity,
        dealId,
        organizationId: user?.organizationId,
        createdAt: new Date().toISOString(),
        createdBy: user?.uid,
        status: 'Completed'
      };

      console.log('Saving activity:', activityToAdd);
      const activitiesRef = collection(db, 'deals', dealId, 'activities');
      await addDoc(activitiesRef, activityToAdd);
      
      // Add activity to deal history
      const dealRef = doc(db, 'deals', dealId);
      const dealDoc = await getDoc(dealRef);
      if (dealDoc.exists()) {
        const deal = dealDoc.data();
        const historyEntry = {
          from: '',
          to: `New Activity Logged - ${activityToAdd.title}`,
          timestamp: activityToAdd.createdAt
        };
        await updateDoc(dealRef, {
          stageHistory: [...(deal.stageHistory || []), historyEntry]
        });
      }
      
      console.log('Activity saved successfully');
      await fetchActivities();
      
      setNewActivity({
        title: '',
        description: '',
        actionedDate: new Date().toISOString(),
        status: 'Completed'
      });
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding activity:', error);
    }
  };

  const handleDeleteActivity = async (activityId: string) => {
    try {
      const activityRef = doc(db, 'deals', dealId, 'activities', activityId);
      await deleteDoc(activityRef);
      setActivities(activities.filter(activity => activity.id !== activityId));
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  const handleEditActivity = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!editingActivity?.id) return;

      const updatedActivity = {
        ...editingActivity,
        lastEditedAt: new Date().toISOString(),
        editedByUser: user?.uid
      };

      const activityRef = doc(db, 'deals', dealId, 'activities', editingActivity.id);
      await updateDoc(activityRef, updatedActivity);

      // Update deal history to reflect edit
      const dealRef = doc(db, 'deals', dealId);
      const dealDoc = await getDoc(dealRef);
      if (dealDoc.exists()) {
        const deal = dealDoc.data();
        const historyEntry = {
          from: '',
          to: `Activity Edited - ${updatedActivity.title}`,
          timestamp: updatedActivity.lastEditedAt
        };
        await updateDoc(dealRef, {
          stageHistory: [...(deal.stageHistory || []), historyEntry]
        });
      }

      await fetchActivities();
      setEditingActivity(null);
    } catch (error) {
      console.error('Error editing activity:', error);
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return '';
      }
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      });
    } catch {
      return '';
    }
  };

  const renderActivityItem = (activity: DealActivity) => {
    if (editingActivity && editingActivity.id === activity.id) {
      return (
        <div className="border border-navy-700 bg-navy-800/50 p-4 rounded-lg">
          <form onSubmit={handleEditActivity} className="space-y-4">
            <div>
              <label className="block text-sm mb-1 text-gray-200">Activity Title</label>
              <input
                value={editingActivity.title}
                onChange={(e) =>
                  setEditingActivity({ ...editingActivity, title: e.target.value })
                }
                placeholder="Enter activity title"
                className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm mb-1 text-gray-200">Description</label>
              <input
                value={editingActivity.description}
                onChange={(e) =>
                  setEditingActivity({ ...editingActivity, description: e.target.value })
                }
                placeholder="Enter activity description"
                className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div>
              <label className="block text-sm mb-1 text-gray-200">Actioned Date</label>
              <DatePicker
                selected={new Date(editingActivity.actionedDate)}
                onChange={(date) =>
                  setEditingActivity({
                    ...editingActivity,
                    actionedDate: (date || new Date()).toISOString(),
                  })
                }
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            
            <div className="flex justify-end gap-2 pt-2">
              <button
                type="button"
                onClick={() => setEditingActivity(null)}
                className="px-4 py-2 text-sm bg-navy-700 hover:bg-navy-600 rounded-md transition-colors text-white font-medium"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-400 rounded-md transition-colors text-white font-medium"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div
        key={activity.id}
        className="flex items-center space-x-3 border border-navy-700 bg-navy-800/50 p-4 rounded-lg"
      >
        <div className="flex-grow">
          <div className="text-white">
            {activity.title}
            {activity.lastEditedAt && (
              <span className="text-xs text-gray-500 ml-2">
                (Edited {formatDate(activity.lastEditedAt)})
              </span>
            )}
          </div>
          {activity.description && (
            <div className="text-xs text-gray-400 mt-1">{activity.description}</div>
          )}
          <div className="text-xs text-gray-500 mt-2 flex items-center gap-2">
            <Check className="h-3 w-3 text-green-500" />
            <span>{formatDate(activity.actionedDate)}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => setEditingActivity(activity)}
            className="text-gray-400 hover:text-blue-400"
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => handleDeleteActivity(activity.id)}
            className="text-gray-400 hover:text-red-400"
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg text-white">Activities</div>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="text-blue-400 hover:text-blue-300 flex items-center gap-1 text-sm font-medium"
        >
          + Add Activity
        </button>
      </div>

      <div className="space-y-2">
        {activities.map(renderActivityItem)}

        {activities.length === 0 && (
          <div className="text-sm text-gray-500 text-center py-8 bg-navy-800/40 rounded-md">
            No activities yet
          </div>
        )}
      </div>

      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-navy-800 rounded-lg w-[400px] overflow-hidden">
            <div className="p-4 border-b border-navy-700">
              <h3 className="text-lg font-medium text-white">Log Activity</h3>
            </div>
            
            <form onSubmit={handleAddActivity} className="p-4 space-y-4">
              <div>
                <label className="block text-sm mb-1 text-gray-200">Activity Title</label>
                <input
                  value={newActivity.title}
                  onChange={(e) =>
                    setNewActivity({ ...newActivity, title: e.target.value })
                  }
                  placeholder="Enter activity title"
                  className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm mb-1 text-gray-200">Description</label>
                <input
                  value={newActivity.description}
                  onChange={(e) =>
                    setNewActivity({ ...newActivity, description: e.target.value })
                  }
                  placeholder="Enter activity description"
                  className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              
              <div>
                <label className="block text-sm mb-1 text-gray-200">Actioned Date</label>
                <DatePicker
                  selected={new Date(newActivity.actionedDate)}
                  onChange={(date) =>
                    setNewActivity({
                      ...newActivity,
                      actionedDate: (date || new Date()).toISOString(),
                    })
                  }
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="w-full px-3 py-2 bg-navy-900 rounded-md border border-navy-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              
              <div className="flex justify-end gap-2 pt-2">
                <button
                  type="button"
                  onClick={() => setIsAddModalOpen(false)}
                  className="px-4 py-2 text-sm bg-navy-700 hover:bg-navy-600 rounded-md transition-colors text-white font-medium"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-400 rounded-md transition-colors text-white font-medium"
                >
                  Log Activity
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
