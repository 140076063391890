import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LayoutDashboard,
  Bell,
  Users,
  BarChart3,
  Calendar,
  MessageSquare,
  ChevronRight,
} from 'lucide-react';
import { useCountUp } from '../../hooks/useCountUp';
import { FAQSection } from './FAQSection';

export const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: <LayoutDashboard className="w-6 h-6 text-indigo-400" />,
      title: 'User Information',
      description: 'Complete view of customer interactions and history.',
    },
    {
      icon: <BarChart3 className="w-6 h-6 text-indigo-400" />,
      title: 'Deal Tracking',
      description: 'Monitor deals through your customized sales pipeline.',
    },
    {
      icon: <Users className="w-6 h-6 text-indigo-400" />,
      title: 'Pipeline Management',
      description: 'Optimize your sales process with visual pipeline tools.',
    },
    {
      icon: <Calendar className="w-6 h-6 text-indigo-400" />,
      title: 'Task Management',
      description: 'Stay organized with integrated calendar and task tracking.',
    },
    {
      icon: <MessageSquare className="w-6 h-6 text-indigo-400" />,
      title: 'Team Collaboration',
      description: 'Seamless communication and file sharing within your team.',
    },
    {
      icon: <Bell className="w-6 h-6 text-indigo-400" />,
      title: 'Smart Notifications',
      description: 'Get timely alerts for important updates and deadlines.',
    },
  ];

  const stats = [
    { label: 'Total Revenue', value: 628522 },
    { label: 'Active Users', value: 1182 },
    { label: 'Deals Closed', value: 4890 },
    { label: 'Avg. Deal Size', value: 4877 },
  ];

  return (
    <div className="min-h-screen bg-navy-900 overflow-hidden">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-navy-900/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <svg className="w-8 h-8 text-indigo-500 animate-float" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 9L12 4L21 9L12 14L3 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 14L12 19L21 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span className="ml-2 text-xl font-bold text-white">NextClient CRM</span>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/signin')}
                className="text-sm font-medium text-gray-300 hover:text-white transition-colors"
              >
                Sign in
              </button>
              <button
                onClick={() => navigate('/signup')}
                className="text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-lg transition-colors"
              >
                Get a demo
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative pt-24 pb-20 sm:pt-32 sm:pb-28">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Streamline your sales process
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Join thousands of sales teams who have already increased their sales with NextClient CRM.
              </p>
              <div className="mt-8 flex gap-4 sm:justify-center lg:justify-start animate-fade-in-delay-2">
                <button
                  onClick={() => navigate('/signup')}
                  className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
                >
                  Get started
                  <ChevronRight className="ml-2 w-5 h-5" />
                </button>
                <button
                  onClick={() => navigate('/signup')}
                  className="inline-flex items-center px-6 py-3 text-base font-medium text-gray-300 hover:text-white bg-navy-800/50 hover:bg-navy-700 rounded-lg border border-gray-700 hover:border-gray-600 transition-colors"
                >
                  View pricing
                </button>
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
              <div className="relative">
                <div className="absolute -inset-y-px -inset-x-4 md:-inset-x-6 lg:-inset-x-8">
                  <div className="absolute inset-0 bg-gradient-to-tr from-indigo-600/20 to-purple-600/20 rounded-3xl" />
                  <div className="absolute inset-0 bg-grid-white/[0.02] rounded-3xl" />
                </div>
                {/* Dashboard Preview */}
                <div className="relative bg-navy-800/50 backdrop-blur-sm rounded-3xl shadow-2xl border border-white/10 overflow-hidden animate-float">
                  <img
                    src="/dashboard-preview.svg"
                    alt="NextClient CRM Dashboard Preview"
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            {/* First row */}
            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <LayoutDashboard className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">User Information</h3>
              <p className="mt-2 text-gray-400">
                Complete view of customer interactions and history.
              </p>
            </div>

            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <BarChart3 className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">Deal Tracking</h3>
              <p className="mt-2 text-gray-400">
                Monitor deals through your customized sales pipeline.
              </p>
            </div>

            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <Users className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">Pipeline Management</h3>
              <p className="mt-2 text-gray-400">
                Optimize your sales process with visual pipeline tools.
              </p>
            </div>

            {/* Second row */}
            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <Calendar className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">Task Management</h3>
              <p className="mt-2 text-gray-400">
                Stay organized with integrated calendar and task tracking.
              </p>
            </div>

            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <MessageSquare className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">Team Collaboration</h3>
              <p className="mt-2 text-gray-400">
                Seamless communication and file sharing within your team.
              </p>
            </div>

            <div className="p-6 transition-all duration-300 rounded-lg bg-navy-800 hover:bg-navy-700">
              <div className="p-3 w-fit bg-navy-700 rounded-xl">
                <Bell className="w-6 h-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-white">Smart Notifications</h3>
              <p className="mt-2 text-gray-400">
                Get timely alerts for important updates and deadlines.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative bg-navy-800/30 border-y border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {stats.map((stat, index) => (
              <div className="text-center animate-fade-in-up" key={index}>
                <div className="text-4xl font-bold text-white">
                  {stat.label === 'Total Revenue' || stat.label === 'Avg. Deal Size' ? '$' : ''}
                  {useCountUp(stat.value)}
                </div>
                <div className="mt-2 text-sm text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <FAQSection />

      {/* CTA Section */}
      <div className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-6">
            Ready to transform your sales process?
          </h2>
          <p className="text-xl text-gray-300 mb-10 max-w-2xl mx-auto">
            Join thousands of engineering teams who have already improved their productivity
            with NextClient CRM.
          </p>
          <button
            onClick={() => navigate('/signup')}
            className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
          >
            Start your free trial
            <ChevronRight className="ml-2 w-6 h-6" />
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-navy-800/50 backdrop-blur-sm border-t border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center text-gray-400">
            <p>&copy; 2024 NextClient CRM by <a href="https://jcemedia.com/" className="hover:text-white transition-colors">JCE Media</a>. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
