import React, { useState, useEffect } from 'react';
import { X, Trash2 } from 'lucide-react';
import { useKanbanStore } from '../../../store/kanbanStore';
import { 
  DealProgressBar, 
  MobileStageSelect, 
  DealHistory, 
  Activities,
  Tasks,
  DealNotes
} from '..';
import { DealMainForm } from './DealMainForm';
import type { Deal } from '../../../types/deal';

interface DealDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  deal: Deal;
}

export const DealDetailsModal: React.FC<DealDetailsModalProps> = ({ isOpen, onClose, deal }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('notes');
  const { deleteDeal, updateDeal } = useKanbanStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalContent = document.querySelector('.modal-content');
      const target = event.target as Node;
      
      if (modalContent && !modalContent.contains(target)) {
        if (hasUnsavedChanges) {
          if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
            onClose();
          }
        } else {
          onClose();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, hasUnsavedChanges, onClose]);

  const handleStageChange = async (stage: string) => {
    await updateDeal(deal.id, { stage });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black/50">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-start justify-center p-4 sm:p-6">
          <div className="modal-content relative w-full max-w-4xl bg-navy-800 rounded-lg shadow-lg mt-16">
            {/* Header - Fixed at top */}
            <div className="sticky top-0 z-10 flex items-center justify-between p-6 border-b border-navy-700 bg-navy-800 rounded-t-lg">
              <h2 className="text-xl font-semibold text-white truncate">{deal.title}</h2>
              <div className="flex items-center gap-2 flex-shrink-0">
                <button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this deal?')) {
                      deleteDeal(deal.id);
                      onClose();
                    }
                  }}
                  className="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-navy-700/50 transition-colors"
                  title="Delete deal"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
                <button 
                  onClick={onClose}
                  className="p-2 text-gray-400 hover:text-white rounded-full hover:bg-navy-700/50 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Progress bar on desktop, stage select on mobile */}
            <div className="hidden md:block">
              <DealProgressBar deal={deal} />
            </div>
            <div className="block md:hidden">
              <MobileStageSelect 
                deal={deal}
                onStageChange={handleStageChange}
                disabled={loading}
              />
            </div>

            {/* Main Content - Scrollable */}
            <div className="p-6 space-y-6 overflow-y-auto">
              {error && (
                <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-md">
                  <p className="text-sm text-red-400">{error}</p>
                </div>
              )}

              {/* Deal Main Form */}
              {/* TODO: Potential future modifications */}
              <DealMainForm 
                deal={deal} 
                onUnsavedChanges={setHasUnsavedChanges}
                loading={loading}
              />

              {/* Tabs Section */}
              <div className="mt-8">
                <div className="flex border-b border-navy-700">
                  <button
                    onClick={() => setActiveTab('notes')}
                    className={`px-4 py-2 ${
                      activeTab === 'notes'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                  >
                    Notes
                  </button>
                  <button
                    onClick={() => setActiveTab('tasks')}
                    className={`px-4 py-2 ml-4 ${
                      activeTab === 'tasks'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                  >
                    Tasks
                  </button>
                  <button
                    onClick={() => setActiveTab('activities')}
                    className={`px-4 py-2 ml-4 ${
                      activeTab === 'activities'
                        ? 'text-blue-500 border-b-2 border-blue-500'
                        : 'text-gray-400 hover:text-gray-300'
                    }`}
                  >
                    Activities
                  </button>
                </div>

                <div className="mt-4 bg-navy-900/50 rounded-lg border border-navy-700 p-4">
                  {activeTab === 'notes' && <DealNotes deal={deal} />}
                  {activeTab === 'tasks' && <Tasks dealId={deal.id} />}
                  {activeTab === 'activities' && <Activities dealId={deal.id} />}
                </div>
              </div>

              <DealHistory stageHistory={deal.stageHistory || []} createdAt={deal.createdAt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};