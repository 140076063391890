import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import { Button } from '../ui';
import { Plus, Calendar, Clock, AlertCircle, Trash2, ExternalLink, Check, Edit2 } from 'lucide-react';
import { format, isToday, isPast, isFuture, parseISO, addDays, startOfDay, endOfDay } from 'date-fns';
import { Link } from 'react-router-dom';
import type { Task } from '../../types/task';
import { TaskForm } from './TaskForm';
import { TaskFilters, type TaskFilters as TaskFiltersType } from './TaskFilters';

export const ActivityFeed = () => {
  const { user } = useAuthStore();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
  const [assignees, setAssignees] = useState<Array<{ id: string; name: string; photoURL?: string }>>([]);
  const [filters, setFilters] = useState<TaskFiltersType>({
    search: '',
    priority: 'all',
    type: 'all',
    dueDate: 'all',
    assigneeId: 'all',
  });

  const fetchAssignees = async () => {
    if (!user?.organizationId) return;

    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('organizationId', '==', user.organizationId));
      const querySnapshot = await getDocs(q);
      const fetchedAssignees = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().displayName || doc.data().email || 'Unknown User',
        photoURL: doc.data().photoURL
      }));
      
      console.log('Fetched assignees:', fetchedAssignees); // Debug log
      setAssignees(fetchedAssignees);
    } catch (error) {
      console.error('Error fetching assignees:', error);
    }
  };

  const fetchTasks = async () => {
    if (!user?.organizationId) return;

    try {
      setLoading(true);
      const tasksRef = collection(db, 'tasks');
      let queryConstraints: any[] = [
        where('organizationId', '==', user.organizationId),
        orderBy('dueDate', 'asc')
      ];

      // Apply filters
      if (filters.priority !== 'all') {
        queryConstraints.push(where('priority', '==', filters.priority));
      }

      if (filters.type !== 'all') {
        queryConstraints.push(where('type', '==', filters.type));
      }

      if (filters.assigneeId !== 'all') {
        queryConstraints.push(where('assigneeId', '==', filters.assigneeId));
      }

      // Due date filter
      const now = new Date();
      if (filters.dueDate === 'today') {
        queryConstraints = [
          ...queryConstraints,
          where('dueDate', '>=', startOfDay(now).toISOString()),
          where('dueDate', '<=', endOfDay(now).toISOString())
        ];
      } else if (filters.dueDate === 'overdue') {
        queryConstraints.push(where('dueDate', '<', startOfDay(now).toISOString()));
      } else if (filters.dueDate === 'next7days') {
        queryConstraints = [
          ...queryConstraints,
          where('dueDate', '>=', now.toISOString()),
          where('dueDate', '<=', addDays(now, 7).toISOString())
        ];
      }

      const q = query(tasksRef, ...queryConstraints);
      const querySnapshot = await getDocs(q);
      let fetchedTasks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Task[];

      // Apply search filter client-side
      if (filters.search) {
        const searchLower = filters.search.toLowerCase();
        fetchedTasks = fetchedTasks.filter(task => 
          task.title.toLowerCase().includes(searchLower) ||
          task.description?.toLowerCase().includes(searchLower) ||
          task.dealName?.toLowerCase().includes(searchLower)
        );
      }

      // Fetch deal names for tasks with dealId
      const tasksWithDeals = await Promise.all(
        fetchedTasks.map(async task => {
          if (task.dealId) {
            try {
              const dealDoc = await getDoc(doc(db, 'deals', task.dealId));
              if (dealDoc.exists()) {
                const dealData = dealDoc.data();
                return {
                  ...task,
                  dealName: dealData.name || dealData.title || 'Unnamed Deal'
                };
              }
            } catch (error) {
              console.error(`Error fetching deal ${task.dealId}:`, error);
            }
          }
          return task;
        })
      );

      setTasks(tasksWithDeals);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignees();
  }, [user?.organizationId]);

  useEffect(() => {
    fetchTasks();
  }, [user?.organizationId, filters]);

  const handleCreateTask = async (taskData: Partial<Task>) => {
    if (!user?.organizationId) return;

    try {
      const newTask = {
        ...taskData,
        status: 'pending',
        organizationId: user.organizationId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'tasks'), newTask);
      await fetchTasks();
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const handleEditTask = (taskToEdit: Task) => {
    setEditingTask({
      ...taskToEdit,
      assigneeId: taskToEdit.assigneeId || '',
      assigneeName: taskToEdit.assigneeName || '',
      assigneePhotoURL: taskToEdit.assigneePhotoURL
    });
  };

  const handleUpdateTask = async (updatedTaskData: Partial<Task>) => {
    if (!editingTask?.id || !user?.organizationId) return;

    try {
      const taskRef = doc(db, 'tasks', editingTask.id);
      await updateDoc(taskRef, {
        ...updatedTaskData,
        updatedAt: serverTimestamp(),
      });

      // Update the task in the local state
      setTasks(tasks.map(task => 
        task.id === editingTask.id 
          ? { 
              ...task, 
              ...updatedTaskData,
              assigneeId: updatedTaskData.assigneeId || task.assigneeId,
              assigneeName: updatedTaskData.assigneeName || task.assigneeName,
              assigneePhotoURL: updatedTaskData.assigneePhotoURL || task.assigneePhotoURL
            } 
          : task
      ));
      setEditingTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await deleteDoc(doc(db, 'tasks', taskId));
      setTasks(tasks.filter(task => task.id !== taskId));
      setConfirmDelete(null);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleToggleComplete = async (task: Task) => {
    const newStatus = task.status === 'completed' ? 'pending' : 'completed';
    await handleUpdateTask({ status: newStatus });
  };

  const groupTasks = (tasks: Task[]) => {
    return tasks.reduce((groups, task) => {
      const dueDate = parseISO(task.dueDate);
      let group = 'upcoming';

      if (task.status === 'completed') {
        group = 'completed';
      } else if (isPast(dueDate) && !isToday(dueDate)) {
        group = 'overdue';
      } else if (isToday(dueDate)) {
        group = 'today';
      } else if (isFuture(dueDate)) {
        group = 'upcoming';
      }

      return {
        ...groups,
        [group]: [...(groups[group] || []), task],
      };
    }, {} as Record<string, Task[]>);
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-yellow-500';
      case 'low':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const renderAssignee = (task: Task) => {
    if (!task.assigneeId) return null;

    return (
      <div className="flex items-center space-x-2">
        {task.assigneePhotoURL ? (
          <img
            src={task.assigneePhotoURL}
            alt={task.assigneeName}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 rounded-full bg-navy-600 flex items-center justify-center">
            <span className="text-xs text-white">
              {task.assigneeName?.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
        <span className="text-sm text-gray-400">{task.assigneeName}</span>
      </div>
    );
  };

  const groupedTasks = groupTasks(tasks);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">Activity Feed</h1>
        <Button onClick={() => setIsAddTaskModalOpen(true)}>
          <Plus className="w-4 h-4 mr-2" />
          Add Task
        </Button>
      </div>

      <div className="mb-6">
        <TaskFilters
          filters={filters}
          onFilterChange={setFilters}
          assignees={assignees}
        />
      </div>

      {Object.entries(groupedTasks).map(([group, tasks]) => (
        <div key={group} className="mb-8">
          <h2 className="text-lg font-semibold text-white capitalize mb-4">
            {group} ({tasks.length})
          </h2>
          <div className="space-y-4">
            {tasks.map((task) => (
              <div
                key={task.id}
                className="bg-navy-800 rounded-lg p-4 hover:bg-navy-700 transition-colors"
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-start space-x-3 flex-grow">
                    <button
                      onClick={() => handleToggleComplete(task)}
                      className={`mt-1 w-5 h-5 rounded border flex items-center justify-center flex-shrink-0 ${
                        task.status === 'completed'
                          ? 'bg-green-500 border-green-500'
                          : 'border-gray-500 hover:border-gray-400'
                      }`}
                    >
                      {task.status === 'completed' && (
                        <Check className="w-3 h-3 text-white" />
                      )}
                    </button>
                    <div>
                      <div className="flex items-center space-x-2">
                        <h3 className={`text-white font-medium ${
                          task.status === 'completed' ? 'line-through text-gray-400' : ''
                        }`}>
                          {task.title}
                        </h3>
                        <button
                          onClick={() => handleEditTask(task)}
                          className="text-gray-400 hover:text-gray-300"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                      </div>
                      {task.description && (
                        <p className="text-gray-400 text-sm mt-1">{task.description}</p>
                      )}
                      {task.dealId && (
                        <Link
                          to={`/deals/${task.dealId}`}
                          className="text-sm text-blue-400 hover:text-blue-300 mt-2 inline-flex items-center gap-1 bg-navy-700/50 px-2 py-1 rounded"
                        >
                          <ExternalLink className="w-3 h-3" />
                          {task.dealName || 'View Deal'}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span
                      className={`px-2 py-1 rounded text-xs font-medium ${getPriorityColor(
                        task.priority
                      )}`}
                    >
                      {task.priority}
                    </span>
                    <button
                      onClick={() => setConfirmDelete(task.id)}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-3">
                  <div className="flex items-center space-x-4 text-sm text-gray-400">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {format(parseISO(task.dueDate), 'MMM d, yyyy')}
                    </span>
                    <span className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      {format(parseISO(task.dueDate), 'h:mm a')}
                    </span>
                  </div>
                  {renderAssignee(task)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      {isAddTaskModalOpen && (
        <TaskForm
          onSubmit={handleCreateTask}
          onClose={() => setIsAddTaskModalOpen(false)}
        />
      )}

      {editingTask && (
        <TaskForm
          task={editingTask}
          onSubmit={(taskData) => handleUpdateTask(taskData)}
          onClose={() => setEditingTask(null)}
        />
      )}

      {confirmDelete && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black/50">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-navy-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <AlertCircle className="h-6 w-6 text-red-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-lg font-medium leading-6 text-white">
                    Delete Task
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-400">
                      Are you sure you want to delete this task? This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteTask(confirmDelete)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-navy-700 bg-navy-900 px-4 py-2 text-base font-medium text-gray-300 shadow-sm hover:bg-navy-800 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setConfirmDelete(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
