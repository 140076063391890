import { create } from 'zustand';
import { DealTask } from '../types/deal';
import { db } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where, 
  getDocs,
  orderBy 
} from 'firebase/firestore';
import { useAuthStore } from './authStore';

interface TasksStore {
  tasks: DealTask[];
  loading: boolean;
  error: string | null;
  fetchTasks: (dealId: string) => Promise<void>;
  addTask: (task: Omit<DealTask, 'id' | 'createdAt' | 'createdBy'>) => Promise<void>;
  updateTask: (taskId: string, updates: Partial<DealTask>) => Promise<void>;
  deleteTask: (taskId: string) => Promise<void>;
}

export const useTasksStore = create<TasksStore>((set, get) => ({
  tasks: [],
  loading: false,
  error: null,

  fetchTasks: async (dealId) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const tasksRef = collection(db, 'tasks');
      const q = query(
        tasksRef,
        where('organizationId', '==', user.organizationId),
        where('dealId', '==', dealId),
        orderBy('dueDate', 'asc')
      );
      const querySnapshot = await getDocs(q);
      const fetchedTasks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as DealTask));

      set({ tasks: fetchedTasks, loading: false });
    } catch (error) {
      console.error('Error fetching tasks:', error);
      set({ error: 'Failed to fetch tasks', loading: false });
    }
  },

  addTask: async (taskData) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const tasksRef = collection(db, 'tasks');
      const newTask = {
        ...taskData,
        organizationId: user.organizationId,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        updatedAt: new Date().toISOString(),
        status: 'pending'
      };

      await addDoc(tasksRef, newTask);
      await get().fetchTasks(taskData.dealId);
    } catch (error) {
      console.error('Error adding task:', error);
      set({ error: 'Failed to add task', loading: false });
    }
  },

  updateTask: async (taskId, updates) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const taskRef = doc(db, 'tasks', taskId);
      const updatedData = {
        ...updates,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(taskRef, updatedData);
      const currentTasks = get().tasks;
      const taskToUpdate = currentTasks.find(task => task.id === taskId);
      if (taskToUpdate?.dealId) {
        await get().fetchTasks(taskToUpdate.dealId);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      set({ error: 'Failed to update task', loading: false });
    }
  },

  deleteTask: async (taskId) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const currentTasks = get().tasks;
      const taskToDelete = currentTasks.find(task => task.id === taskId);
      const taskRef = doc(db, 'tasks', taskId);
      
      await deleteDoc(taskRef);
      
      if (taskToDelete?.dealId) {
        await get().fetchTasks(taskToDelete.dealId);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      set({ error: 'Failed to delete task', loading: false });
    }
  }
}));
