export const urlRegex = /(https?:\/\/[^\s]+)/g;

export const isValidUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export const splitTextWithUrls = (text: string) => {
  const parts = text.split(urlRegex);
  const matches = text.match(urlRegex) || [];
  const result = parts.reduce((acc: { text: string; isUrl: boolean }[], part, i) => {
    if (part) {
      acc.push({ text: part, isUrl: matches.includes(part) });
    }
    return acc;
  }, []);
  return result;
};
