import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LayoutDashboard, Settings, LogOut, Plus, BarChart2, ListTodo } from 'lucide-react';
import { NotificationBell } from '../notifications/NotificationBell';

interface SidebarNavProps {
  onNavClick: () => void;
  onAddDeal: () => void;
  onSignOut: () => void;
}

export const SidebarNav: React.FC<SidebarNavProps> = ({
  onNavClick,
  onAddDeal,
  onSignOut,
}) => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="flex flex-col h-full">
      <nav className="flex-1 px-4 py-12 space-y-2">
        <Link
          to="/dashboard"
          onClick={onNavClick}
          className={`flex items-center px-4 py-2 text-gray-300 rounded-md transition-colors ${
            isActive('/dashboard') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
          }`}
        >
          <LayoutDashboard className="w-5 h-5 mr-3" />
          Dashboard
        </Link>

        <Link
          to="/activity"
          onClick={onNavClick}
          className={`flex items-center px-4 py-2 text-gray-300 rounded-md transition-colors ${
            isActive('/activity') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
          }`}
        >
          <ListTodo className="w-5 h-5 mr-3" />
          Activity Feed
        </Link>

        <Link
          to="/reports"
          onClick={onNavClick}
          className={`flex items-center px-4 py-2 text-gray-300 rounded-md transition-colors ${
            isActive('/reports') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
          }`}
        >
          <BarChart2 className="w-5 h-5 mr-3" />
          Reports
        </Link>

        <Link
          to="/settings"
          onClick={onNavClick}
          className={`flex items-center px-4 py-2 text-gray-300 rounded-md transition-colors ${
            isActive('/settings') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
          }`}
        >
          <Settings className="w-5 h-5 mr-3" />
          Settings
        </Link>
      </nav>

      <div className="flex-1" />

      <div className="px-4 pb-6">
        <button
          onClick={onAddDeal}
          className="flex items-center w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5 mr-3" />
          Add Deal
        </button>
      </div>

      <div className="px-4 py-4 space-y-2 border-t border-navy-700">
        <NotificationBell />
        <button
          onClick={onSignOut}
          className="flex items-center w-full px-4 py-2 text-gray-300 rounded-md hover:bg-navy-700 hover:text-white transition-colors"
        >
          <LogOut className="w-5 h-5 mr-3" />
          Sign Out
        </button>
      </div>
    </div>
  );
};