import { Bell } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNotificationsStore } from '../../store/notificationsStore';
import { format } from 'date-fns';

export function NotificationBell() {
  const [isOpen, setIsOpen] = useState(false);
  const { notifications, unreadCount, subscribeToNotifications, markAsRead, markAllAsRead } = useNotificationsStore();

  useEffect(() => {
    const unsubscribe = subscribeToNotifications();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const handleMarkAllAsRead = () => {
    markAllAsRead();
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-4 py-2 text-gray-300 rounded-md hover:bg-navy-700 hover:text-white transition-colors w-full"
      >
        <div className="relative">
          <Bell className="w-5 h-5 mr-3" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-blue-500 rounded-full">
              {unreadCount}
            </span>
          )}
        </div>
        Notifications
      </button>

      {isOpen && (
        <div className="absolute left-full ml-2 top-0 w-80 bg-navy-800 rounded-lg shadow-lg border border-navy-700 z-50">
          <div className="flex items-center justify-between p-4 border-b border-navy-700">
            <h3 className="text-sm font-medium text-white">Notifications</h3>
            {unreadCount > 0 && (
              <button
                onClick={handleMarkAllAsRead}
                className="text-xs text-blue-400 hover:text-blue-300"
              >
                Mark all as read
              </button>
            )}
          </div>

          <div className="max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <div className="p-4 text-sm text-gray-400 text-center">
                No notifications
              </div>
            ) : (
              <div className="divide-y divide-navy-700">
                {notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`p-4 hover:bg-navy-700/50 ${
                      !notification.read ? 'bg-navy-700/20' : ''
                    }`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    <div className="flex items-start gap-3">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-white">
                          {notification.title}
                        </p>
                        <p className="text-sm text-gray-400 mt-0.5">
                          {notification.message}
                        </p>
                        <p className="text-xs text-gray-500 mt-1">
                          {format(notification.createdAt, 'MMM d, h:mm a')}
                        </p>
                      </div>
                      {!notification.read && (
                        <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
