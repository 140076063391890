import React, { useState, useEffect, useCallback } from 'react';
import { 
  Checkbox, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  Input,
  DialogFooter,
  DialogDescription
} from '../ui';
import { useTasksStore } from '../../store/tasksStore';
import { useAuthStore } from '../../store/authStore';
import { Plus, Trash2, Edit, Clock, Calendar, Edit2, AlertCircle } from 'lucide-react';
import { DealTask } from '../../types/deal';
import { format, isPast, parseISO, addDays, addWeeks, startOfDay, isToday, isTomorrow } from 'date-fns';
import debounce from 'lodash/debounce';
import { db } from '../../lib/firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';

interface TasksProps {
  dealId: string;
}

const DEBOUNCE_DELAY = 300; // 300ms delay

export const Tasks: React.FC<TasksProps> = ({ dealId }) => {
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const [editingTask, setEditingTask] = useState<DealTask | null>(null);
  const [completedTask, setCompletedTask] = useState<DealTask | null>(null);
  const [isLogActivityOpen, setIsLogActivityOpen] = useState(false);
  const [activityNote, setActivityNote] = useState('');
  const [users, setUsers] = useState<Array<{ id: string; name: string; photoURL?: string; jobTitle?: string; role?: string }>>([]);
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    priority: 'low' as 'low' | 'medium' | 'high',
    status: 'pending' as 'pending' | 'completed',
    dueDate: new Date().toISOString().slice(0, 16),
    dealId: dealId,
    type: 'Call' as 'Call' | 'Email' | 'Demo' | 'Meeting' | 'Follow-up',
    assigneeId: '',
    assigneeName: '',
    assigneePhotoURL: ''
  });

  const { tasks, loading, error, fetchTasks, addTask, updateTask, deleteTask } = useTasksStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (dealId) {
      fetchTasks(dealId);
    }
  }, [dealId, fetchTasks]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!user?.organizationId) {
        console.log('No organization ID found');
        return;
      }
      
      try {
        console.log('Fetching users for organization:', user.organizationId);
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('organizationId', '==', user.organizationId));
        const querySnapshot = await getDocs(q);
        
        const fetchedUsers = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.displayName || 'Unnamed User',
            photoURL: data.photoURL || '',
            jobTitle: data.jobTitle || '',
            role: data.role || 'member'
          };
        });
        
        const sortedUsers = fetchedUsers.sort((a, b) => {
          if (a.role === 'owner' && b.role !== 'owner') return -1;
          if (a.role !== 'owner' && b.role === 'owner') return 1;
          return a.name.localeCompare(b.name);
        });
        
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [user?.organizationId]);

  const handleAddTask = async () => {
    if (!user?.organizationId || !dealId) return;

    try {
      await addTask({
        ...newTask,
        dealId,
        organizationId: user.organizationId,
      });
      
      setIsAddTaskOpen(false);
      setNewTask({
        title: '',
        description: '',
        priority: 'low',
        status: 'pending',
        dueDate: new Date().toISOString().slice(0, 16),
        dealId: dealId,
        type: 'Call',
        assigneeId: '',
        assigneeName: '',
        assigneePhotoURL: ''
      });
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const handleTaskComplete = async (task: DealTask) => {
    setCompletedTask(task);
    setIsLogActivityOpen(true);
  };

  const handleLogActivity = async () => {
    if (!completedTask || !user) return;

    try {
      await updateTask(completedTask.id, { status: 'completed' });

      const activitiesRef = collection(db, 'activities');
      await addDoc(activitiesRef, {
        title: `Completed task: ${completedTask.title}`,
        description: activityNote,
        dealId,
        organizationId: user.organizationId,
        actionedDate: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        status: 'Completed'
      });

      setIsLogActivityOpen(false);
      setCompletedTask(null);
      setActivityNote('');
    } catch (error) {
      console.error('Error logging activity:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-32">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4 text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Tasks ({tasks.length})</h3>
        <Button
          onClick={() => setIsAddTaskOpen(true)}
          className="bg-blue-600 hover:bg-blue-700 text-white"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Task
        </Button>
      </div>

      {tasks.length === 0 ? (
        <div className="text-center py-8 bg-navy-800/50 rounded-lg">
          <p className="text-gray-400">No tasks for this deal yet</p>
        </div>
      ) : (
        <div className="space-y-3">
          {tasks.map((task) => (
            <div
              key={task.id}
              className={`bg-navy-800/50 rounded-lg p-4 transition-all ${
                task.status === 'completed' ? 'opacity-75' : ''
              }`}
            >
              <div className="flex items-start justify-between">
                <div className="flex items-start space-x-3 flex-grow">
                  <Checkbox
                    checked={task.status === 'completed'}
                    onCheckedChange={() => handleTaskComplete(task)}
                    className="mt-1"
                  />
                  <div className="space-y-1 flex-grow">
                    <div className="flex items-start justify-between">
                      <div>
                        <h4 className={`font-medium ${
                          task.status === 'completed' ? 'text-gray-400 line-through' : 'text-white'
                        }`}>
                          {task.title}
                        </h4>
                        {task.description && (
                          <p className="text-gray-400 text-sm mt-1">{task.description}</p>
                        )}
                      </div>
                      <span className={`px-2 py-1 rounded text-xs font-medium ${
                        task.priority === 'high'
                          ? 'text-red-500'
                          : task.priority === 'medium'
                          ? 'text-yellow-500'
                          : 'text-green-500'
                      }`}>
                        {task.priority}
                      </span>
                    </div>
                    <div className="flex items-center space-x-4 text-sm text-gray-400">
                      <span className="flex items-center">
                        <Calendar className="w-4 h-4 mr-1" />
                        {format(parseISO(task.dueDate), 'MMM d, yyyy')}
                      </span>
                      <span className="flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        {format(parseISO(task.dueDate), 'h:mm a')}
                      </span>
                      {task.type && (
                        <span className="flex items-center">
                          <AlertCircle className="w-4 h-4 mr-1" />
                          {task.type}
                        </span>
                      )}
                    </div>
                    {task.assigneeId && (
                      <div className="flex items-center mt-2 space-x-2">
                        {task.assigneePhotoURL ? (
                          <img
                            src={task.assigneePhotoURL}
                            alt={task.assigneeName}
                            className="w-6 h-6 rounded-full"
                          />
                        ) : (
                          <div className="w-6 h-6 rounded-full bg-navy-600 flex items-center justify-center">
                            <span className="text-xs text-white">
                              {task.assigneeName?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <span className="text-sm text-gray-400">{task.assigneeName}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center space-x-2 ml-4">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setEditingTask(task)}
                  >
                    <Edit2 className="w-4 h-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => deleteTask(task.id)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Dialog open={isAddTaskOpen} onOpenChange={setIsAddTaskOpen}>
        <DialogContent className="bg-navy-900 border border-navy-700">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold text-white">Add New Task</DialogTitle>
            <DialogDescription className="text-gray-400">
              Create a new task for this deal
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label htmlFor="title" className="text-sm font-medium text-gray-200">
                Title
              </label>
              <Input
                id="title"
                value={newTask.title}
                onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                className="bg-navy-800 border-navy-700 text-white placeholder-gray-400"
                placeholder="Enter task title"
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="description" className="text-sm font-medium text-gray-200">
                Description
              </label>
              <Input
                id="description"
                value={newTask.description}
                onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                className="bg-navy-800 border-navy-700 text-white placeholder-gray-400"
                placeholder="Enter task description"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label htmlFor="type" className="text-sm font-medium text-gray-200">
                  Type
                </label>
                <select
                  id="type"
                  value={newTask.type}
                  onChange={(e) => setNewTask({ ...newTask, type: e.target.value as any })}
                  className="w-full px-3 py-2 rounded-md bg-navy-800 border border-navy-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  style={{ colorScheme: 'dark' }}
                >
                  <option value="Call" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Call</option>
                  <option value="Email" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Email</option>
                  <option value="Demo" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Demo</option>
                  <option value="Meeting" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Meeting</option>
                  <option value="Follow-up" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Follow-up</option>
                </select>
              </div>

              <div className="space-y-2">
                <label htmlFor="priority" className="text-sm font-medium text-gray-200">
                  Priority
                </label>
                <select
                  id="priority"
                  value={newTask.priority}
                  onChange={(e) => setNewTask({ ...newTask, priority: e.target.value as any })}
                  className="w-full px-3 py-2 rounded-md bg-navy-800 border border-navy-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  style={{ colorScheme: 'dark' }}
                >
                  <option value="low" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Low</option>
                  <option value="medium" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>Medium</option>
                  <option value="high" className="bg-navy-800 text-white py-2" style={{ backgroundColor: '#1a2234' }}>High</option>
                </select>
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="dueDate" className="text-sm font-medium text-gray-200">
                Due Date
              </label>
              <Input
                id="dueDate"
                type="datetime-local"
                value={newTask.dueDate}
                onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                className="bg-navy-800 border-navy-700 text-white"
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="assignee" className="text-sm font-medium text-gray-200">
                Assign To
              </label>
              <div className="relative">
                <select
                  id="assignee"
                  value={newTask.assigneeId}
                  onChange={(e) => {
                    const selectedUser = users.find(u => u.id === e.target.value);
                    setNewTask({
                      ...newTask,
                      assigneeId: selectedUser?.id || '',
                      assigneeName: selectedUser?.name || '',
                      assigneePhotoURL: selectedUser?.photoURL || ''
                    });
                  }}
                  className="w-full px-3 py-2 rounded-md appearance-none bg-navy-800 border border-navy-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" className="bg-[#1a2234] text-gray-400 py-2">Select team member</option>
                  {users.map((user) => (
                    <option 
                      key={user.id} 
                      value={user.id} 
                      className="bg-[#1a2234] text-white py-2"
                    >
                      {user.name}{user.jobTitle ? ` - ${user.jobTitle}` : ''}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <DialogFooter className="border-t border-navy-700 pt-4">
            <Button
              variant="outline"
              onClick={() => setIsAddTaskOpen(false)}
              className="border-navy-700 text-gray-300 hover:bg-navy-800"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddTask}
              className="bg-blue-600 hover:bg-blue-700 text-white ml-2"
              disabled={!newTask.title}
            >
              Add Task
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isLogActivityOpen} onOpenChange={() => setIsLogActivityOpen(false)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Log Activity for Completed Task?</DialogTitle>
            <DialogDescription>
              Would you like to log an activity for completing this task?
            </DialogDescription>
          </DialogHeader>

          <div className="py-4">
            <Input
              value={activityNote}
              onChange={(e) => setActivityNote(e.target.value)}
              placeholder="Add notes about the completed task (optional)"
            />
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setIsLogActivityOpen(false)}>
              Skip
            </Button>
            <Button onClick={handleLogActivity}>
              Log Activity
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
