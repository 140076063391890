import React from 'react';
import { Building2, Link2, ExternalLink } from 'lucide-react';
import { useCustomFieldsStore } from '../../../store/customFieldsStore';
import type { CustomFieldValue } from '../../../types/customField';
import { isValidUrl } from '../../../utils/urlUtils';

interface CompanyInformationFormProps {
  company: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customFields: CustomFieldValue[];
  onCustomFieldChange: (fieldId: string, value: string | number) => void;
}

export const CompanyInformationForm: React.FC<CompanyInformationFormProps> = ({
  company,
  onChange,
  customFields,
  onCustomFieldChange
}) => {
  const { fields } = useCustomFieldsStore();
  const companyFields = fields.filter(field => field.displayLocation === 'company');

  return (
    <div className="bg-navy-900/50 rounded-lg border border-navy-700">
      <div className="px-6 py-4 border-b border-navy-700">
        <h3 className="text-sm font-medium text-white">Company Information</h3>
      </div>
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Company Name
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Building2 className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="text"
              name="company"
              required
              value={company}
              onChange={onChange}
              className="block w-full pl-10 pr-3 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-colors"
            />
          </div>
        </div>

        {/* Company Custom Fields */}
        {companyFields.map(field => {
          const customFieldValue = customFields.find(cf => cf.fieldId === field.id);
          const value = customFieldValue?.value as string || '';
          
          return (
            <div key={field.id} className="md:col-span-1">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                {field.name}
              </label>
              <div className="relative">
                {field.type === 'url' && (
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Link2 className="w-5 h-5 text-gray-500" />
                  </div>
                )}
                <div className="relative group">
                  <input
                    type={field.type === 'number' ? 'number' : 'text'}
                    value={value}
                    onChange={(e) => onCustomFieldChange(field.id, field.type === 'number' ? parseFloat(e.target.value) || 0 : e.target.value)}
                    placeholder="Not set"
                    className={`block w-full ${field.type === 'url' ? 'pl-10' : 'px-3'} py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition-colors`}
                  />
                  {value && isValidUrl(value) && (
                    <a
                      href={value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-blue-400 hover:text-blue-300 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};