import { create } from 'zustand';
import { Notification } from '../types/notification';
import { collection, query, where, orderBy, onSnapshot, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from './authStore';

interface NotificationsState {
  notifications: Notification[];
  unreadCount: number;
  loading: boolean;
  error: string | null;
  subscribeToNotifications: () => void;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  createNotification: (notification: Omit<Notification, 'id' | 'createdAt' | 'read'>) => Promise<void>;
}

export const useNotificationsStore = create<NotificationsState>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  loading: false,
  error: null,

  subscribeToNotifications: () => {
    const userId = useAuthStore.getState().user?.uid;
    if (!userId) return;

    set({ loading: true });

    const q = query(
      collection(db, 'notifications'),
      where('mentionedUser', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const notifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
        })) as Notification[];

        const unreadCount = notifications.filter(n => !n.read).length;

        set({
          notifications,
          unreadCount,
          loading: false,
          error: null,
        });
      },
      (error) => {
        set({ error: error.message, loading: false });
      }
    );

    return unsubscribe;
  },

  markAsRead: async (notificationId: string) => {
    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      await updateDoc(notificationRef, { read: true });
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },

  markAllAsRead: async () => {
    try {
      const { notifications } = get();
      const unreadNotifications = notifications.filter(n => !n.read);
      
      await Promise.all(
        unreadNotifications.map(notification =>
          updateDoc(doc(db, 'notifications', notification.id), { read: true })
        )
      );
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },

  createNotification: async (notification) => {
    try {
      await addDoc(collection(db, 'notifications'), {
        ...notification,
        createdAt: new Date(),
        read: false,
      });
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },
}));
