import React, { useState, useMemo, useEffect } from 'react';
import { X, Building2, User, Mail, Phone, DollarSign, MessageSquare } from 'lucide-react';
import { useKanbanStore } from '../../store/kanbanStore';
import { CustomFieldsSection } from './CustomFieldsSection';
import type { CustomFieldValue } from '../../types/customField';
import { DealInformationForm } from './forms/DealInformationForm';
import { CompanyInformationForm } from './forms/CompanyInformationForm';
import { ContactInformationForm } from './forms/ContactInformationForm';
import { DealOwnerSelect } from './DealOwnerSelect';
import { useTeamStore } from '../../store/teamStore';
import { useCustomFieldsStore } from '../../store/customFieldsStore';

interface AddDealModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialStage?: string;
}

export const AddDealModal: React.FC<AddDealModalProps> = ({ isOpen, onClose, initialStage }) => {
  const [formData, setFormData] = useState({
    title: '',
    value: '',
    company: '',
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    stage: initialStage || 'Lead',
    notes: '',
  });
  const [customFields, setCustomFields] = useState<CustomFieldValue[]>([]);
  const [ownerId, setOwnerId] = useState('');

  const { addDeal, loading, error } = useKanbanStore();
  const { members } = useTeamStore();
  const { fields, subscribeToFields } = useCustomFieldsStore();

  // Subscribe to custom fields when component mounts
  useEffect(() => {
    const unsubscribe = subscribeToFields();
    return () => unsubscribe();
  }, [subscribeToFields]);

  // Log fields when they change
  useEffect(() => {
    console.log('Available Custom Fields:', fields);
  }, [fields]);

  const customFieldSections = useMemo(() => {
    const sections: { [key: string]: typeof fields } = {};
    
    fields.forEach(field => {
      const location = field.displayLocation || 'general';
      console.log('Custom Field:', {
        name: field.name,
        location: location,
        type: field.type
      });

      if (!sections[location]) {
        sections[location] = [];
      }
      sections[location].push(field);
    });

    console.log('Custom Field Sections:', sections);

    return sections;
  }, [fields]);

  const sectionOrder = ['deal information', 'company information', 'contact information', 'general'];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const owner = members.find(m => m.uid === ownerId);
      await addDeal({
        ...formData,
        value: parseFloat(formData.value) || 0,
        customFields,
        ownerId,
        ownerName: owner?.displayName || owner?.email || '',
        ownerPhotoURL: owner?.photoURL,
      });
      setFormData({
        title: '',
        value: '',
        company: '',
        contact: {
          name: '',
          email: '',
          phone: ''
        },
        stage: initialStage || 'Lead',
        notes: '',
      });
      setCustomFields([]);
      setOwnerId('');
      onClose();
    } catch (err) {
      console.error('Error adding deal:', err);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('contact.')) {
      const contactField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        contact: {
          ...prev.contact,
          [contactField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleCustomFieldChange = (fieldId: string, value: string | number) => {
    console.log('Custom Field Change:', fieldId, value);
    setCustomFields(prev => {
      const existingField = prev.find(f => f.fieldId === fieldId);
      if (existingField) {
        return prev.map(f => f.fieldId === fieldId ? { ...f, value } : f);
      }
      return [...prev, { fieldId, value }];
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-navy-800 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="sticky top-0 z-10 bg-navy-800 border-b border-navy-700 rounded-t-lg">
          <div className="px-6 py-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white">Add New Deal</h2>
            <div className="flex items-center gap-2">
              <button 
                onClick={onClose}
                className="p-2 text-gray-400 hover:text-white rounded-full hover:bg-navy-700/50 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-md">
              <p className="text-sm text-red-400">{error}</p>
            </div>
          )}

          {/* Deal Information Section */}
          <div className="bg-navy-900/50 rounded-lg border border-navy-700 mb-6">
            <div className="px-6 py-4 border-b border-navy-700">
              <h3 className="text-sm font-medium text-white">Deal Information</h3>
            </div>
            <div className="p-6 space-y-4">
              {/* Standard Deal Fields */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Deal Title
                  </label>
                  <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => handleChange(e)}
                    name="title"
                    className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter deal title"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Deal Value
                  </label>
                  <input
                    type="number"
                    value={formData.value}
                    onChange={(e) => handleChange(e)}
                    name="value"
                    className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter deal value"
                  />
                </div>
              </div>
              
              {/* Deal Custom Fields */}
              {customFieldSections['deal information'] && (
                <CustomFieldsSection 
                  values={customFields} 
                  onChange={handleCustomFieldChange}
                  section="deal"
                />
              )}
            </div>
          </div>

          {/* Company Information Section */}
          <div className="bg-navy-900/50 rounded-lg border border-navy-700 mb-6">
            <div className="px-6 py-4 border-b border-navy-700">
              <h3 className="text-sm font-medium text-white">Company Information</h3>
            </div>
            <div className="p-6 space-y-4">
              {/* Standard Company Fields */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Company Name
                </label>
                <input
                  type="text"
                  value={formData.company}
                  onChange={(e) => handleChange(e)}
                  name="company"
                  className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter company name"
                  required
                />
              </div>

              {/* Company Custom Fields */}
              {customFieldSections['company information'] && (
                <CustomFieldsSection 
                  values={customFields} 
                  onChange={handleCustomFieldChange}
                  section="company"
                />
              )}
            </div>
          </div>

          {/* Contact Information Section */}
          <div className="bg-navy-900/50 rounded-lg border border-navy-700 mb-6">
            <div className="px-6 py-4 border-b border-navy-700">
              <h3 className="text-sm font-medium text-white">Contact Information</h3>
            </div>
            <div className="p-6 space-y-4">
              {/* Standard Contact Fields */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Contact Name
                  </label>
                  <input
                    type="text"
                    value={formData.contact.name}
                    onChange={(e) => handleChange(e)}
                    name="contact.name"
                    className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter contact name"
                    required
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">
                      Email Address
                    </label>
                    <input
                      type="email"
                      value={formData.contact.email}
                      onChange={(e) => handleChange(e)}
                      name="contact.email"
                      className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      value={formData.contact.phone}
                      onChange={(e) => handleChange(e)}
                      name="contact.phone"
                      className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder="Enter phone number"
                    />
                  </div>
                </div>
              </div>

              {/* Contact Custom Fields */}
              {customFieldSections['contact information'] && (
                <CustomFieldsSection 
                  values={customFields} 
                  onChange={handleCustomFieldChange}
                  section="contact"
                />
              )}
            </div>
          </div>

          {/* Deal Owner */}
          <div className="bg-navy-900/50 rounded-lg border border-navy-700">
            <div className="px-6 py-4 border-b border-navy-700">
              <h3 className="text-sm font-medium text-white">Deal Owner</h3>
            </div>
            <div className="p-6">
              <DealOwnerSelect
                currentOwnerId={ownerId}
                onOwnerChange={setOwnerId}
                disabled={loading}
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-end pt-6 border-t border-navy-700">
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-2.5 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:opacity-50"
            >
              {loading ? 'Creating...' : 'Create Deal'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};