import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { DealDetailsModal } from './modals/DealDetailsModal';
import type { Deal } from '../../types/deal';

export const DealDetailsPage = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const [deal, setDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDeal = async () => {
      if (!dealId) return;

      try {
        const dealDoc = await getDoc(doc(db, 'deals', dealId));
        if (dealDoc.exists()) {
          setDeal({
            id: dealDoc.id,
            ...dealDoc.data()
          } as Deal);
        } else {
          setError('Deal not found');
        }
      } catch (err) {
        setError('Error fetching deal');
        console.error('Error fetching deal:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDeal();
  }, [dealId]);

  const handleClose = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !deal) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-500">{error || 'Deal not found'}</div>
      </div>
    );
  }

  return (
    <DealDetailsModal
      isOpen={true}
      onClose={handleClose}
      deal={deal}
    />
  );
};
