import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const ApiDocs: React.FC = () => {
  const endpoints = [
    {
      method: 'GET',
      path: '/deals',
      description: 'List all deals with pagination and filtering options',
      parameters: [
        { name: 'page', type: 'number', description: 'Page number (default: 1)' },
        { name: 'limit', type: 'number', description: 'Items per page (default: 20)' },
        { name: 'stage', type: 'string', description: 'Filter by deal stage' },
        { name: 'search', type: 'string', description: 'Search in title and company' },
      ],
    },
    {
      method: 'POST',
      path: '/deals',
      description: 'Create a new deal',
      body: {
        title: 'string',
        value: 'number',
        company: 'string',
        contact: {
          name: 'string',
          email: 'string',
          phone: 'string',
        },
        stage: 'string',
        customFields: 'Array<{ fieldId: string, value: any }>',
      },
    },
    {
      method: 'GET',
      path: '/deals/:id',
      description: 'Get a specific deal by ID',
      parameters: [
        { name: 'id', type: 'string', description: 'Deal ID' },
      ],
    },
    {
      method: 'PUT',
      path: '/deals/:id',
      description: 'Update a specific deal',
      parameters: [
        { name: 'id', type: 'string', description: 'Deal ID' },
      ],
      body: {
        title: 'string (optional)',
        value: 'number (optional)',
        company: 'string (optional)',
        contact: 'object (optional)',
        stage: 'string (optional)',
        customFields: 'Array (optional)',
      },
    },
  ];

  return (
    <div className="h-full">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="px-6 py-4">
          <div className="text-center">
            <h1 className="text-2xl font-semibold text-gray-900">API Documentation</h1>
            <p className="mt-2 text-sm text-gray-600">
              Integrate NextClient CRM with your applications using our REST API
            </p>
          </div>

          <div className="mt-8">
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
              <div className="px-6 py-4 border-b border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">Authentication</h2>
              </div>
              <div className="p-6">
                <p className="text-sm text-gray-600">
                  All API requests must include your API token in the Authorization header:
                </p>
                <pre className="mt-3 p-4 bg-gray-50 rounded-md overflow-x-auto">
                  <code className="text-gray-800">
                    Authorization: Bearer YOUR_API_TOKEN
                  </code>
                </pre>
              </div>
            </div>

            <div className="mt-6 space-y-6">
              {endpoints.map((endpoint, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-sm border border-gray-200"
                >
                  <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <span className={`px-3 py-1 rounded-full text-sm font-medium
                        ${endpoint.method === 'GET' ? 'bg-green-100 text-green-700' :
                          endpoint.method === 'POST' ? 'bg-blue-100 text-blue-700' :
                          'bg-yellow-100 text-yellow-700'}`}>
                        {endpoint.method}
                      </span>
                      <code className="text-gray-900">{endpoint.path}</code>
                    </div>
                  </div>
                  <div className="p-6">
                    <p className="text-sm text-gray-600">{endpoint.description}</p>
                    
                    {endpoint.parameters && endpoint.parameters.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-sm font-medium text-gray-900 mb-2">Parameters</h3>
                        <div className="bg-gray-50 rounded-md p-4">
                          {endpoint.parameters.map((param, idx) => (
                            <div key={idx} className="flex items-start space-x-4 mb-2 last:mb-0">
                              <code className="text-gray-800 min-w-[100px]">{param.name}</code>
                              <code className="text-gray-600 min-w-[80px]">{param.type}</code>
                              <span className="text-sm text-gray-600">{param.description}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {endpoint.body && (
                      <div className="mt-4">
                        <h3 className="text-sm font-medium text-gray-900 mb-2">Request Body</h3>
                        <pre className="bg-gray-50 rounded-md p-4 overflow-x-auto">
                          <code className="text-gray-800">
                            {JSON.stringify(endpoint.body, null, 2)}
                          </code>
                        </pre>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8 text-center">
            <p className="text-sm text-gray-600">
              Need help? Contact our support team or visit our help center.
            </p>
            <div className="mt-2">
              <a
                href="/support"
                className="inline-flex items-center text-sm text-blue-600 hover:text-blue-500"
              >
                Get Support
                <FiArrowRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDocs;
