import React, { useState, useEffect } from 'react';
import { auth } from '../../lib/firebase';
import { FiCopy, FiRefreshCw, FiArrowRight } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';

const IntegrationsTab: React.FC = () => {
  const [apiToken, setApiToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showToken, setShowToken] = useState(false);

  const generateNewToken = async () => {
    try {
      setIsLoading(true);
      const token = await auth.currentUser?.getIdToken(true);
      setApiToken(token || '');
      toast.success('New API token generated');
    } catch (error) {
      toast.error('Failed to generate token');
      console.error('Error generating token:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  useEffect(() => {
    generateNewToken();
  }, []);

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold text-gray-900">API Integration</h2>
        <p className="mt-1 text-sm text-gray-500">
          Use these credentials to integrate NextClient CRM with external tools and services.
        </p>
      </div>

      {/* API Base URL */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">API Base URL</h3>
        <div className="flex items-center space-x-2">
          <code className="flex-1 p-3 bg-gray-100 rounded-lg text-gray-800 font-mono text-sm">
            https://api.nextclient.com/v1
          </code>
          <button
            onClick={() => copyToClipboard('https://api.nextclient.com/v1')}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            <FiCopy className="text-gray-600 w-5 h-5" />
          </button>
        </div>
      </div>

      {/* API Token */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">API Token</h3>
        <div className="flex items-center space-x-2">
          <div className="flex-1 p-3 bg-gray-100 rounded-lg font-mono text-sm">
            {showToken ? (
              <code className="text-gray-800 break-all">{apiToken}</code>
            ) : (
              <div className="flex space-x-2">
                <span className="text-gray-400">••••••••••••••••••••••</span>
                <button
                  onClick={() => setShowToken(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  Show token
                </button>
              </div>
            )}
          </div>
          <button
            onClick={() => copyToClipboard(apiToken)}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            <FiCopy className="text-gray-600 w-5 h-5" />
          </button>
          <button
            onClick={generateNewToken}
            disabled={isLoading}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title="Generate new token"
          >
            <FiRefreshCw className={`text-gray-600 w-5 h-5 ${isLoading ? 'animate-spin' : ''}`} />
          </button>
        </div>
      </div>

      {/* Make.com Integration */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Make.com Integration</h3>
        <div className="space-y-8">
          {/* Create New Deal */}
          <div>
            <h4 className="font-medium text-gray-900">Create New Deal Scenario</h4>
            <div className="mt-2 space-y-2">
              <p className="text-gray-600">1. Create a new scenario in Make.com</p>
              <p className="text-gray-600">2. Add an HTTP module and configure it as follows:</p>
              <div className="bg-gray-100 p-4 rounded-lg space-y-2">
                <div>
                  <span className="text-gray-500">URL:</span>
                  <code className="ml-2 text-gray-800">https://api.nextclient.com/v1/deals</code>
                </div>
                <div>
                  <span className="text-gray-500">Method:</span>
                  <code className="ml-2 text-gray-800">POST</code>
                </div>
                <div>
                  <span className="text-gray-500">Headers:</span>
                  <pre className="mt-1 text-sm">
                    <code className="text-gray-800">
                      {JSON.stringify({
                        'Authorization': 'Bearer YOUR_API_TOKEN',
                        'Content-Type': 'application/json'
                      }, null, 2)}
                    </code>
                  </pre>
                </div>
                <div>
                  <span className="text-gray-500">Body Example:</span>
                  <pre className="mt-1 text-sm">
                    <code className="text-gray-800">
                      {JSON.stringify({
                        title: "Deal Title",
                        value: 10000,
                        company: "Company Name",
                        contact: {
                          name: "Contact Name",
                          email: "contact@email.com",
                          phone: "123456789"
                        }
                      }, null, 2)}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>

          {/* List Deals */}
          <div>
            <h4 className="font-medium text-gray-900">List Deals Scenario</h4>
            <div className="mt-2 space-y-2">
              <p className="text-gray-600">Configure the HTTP module as follows to list deals:</p>
              <div className="bg-gray-100 p-4 rounded-lg space-y-2">
                <div>
                  <span className="text-gray-500">URL:</span>
                  <code className="ml-2 text-gray-800">https://api.nextclient.com/v1/deals</code>
                </div>
                <div>
                  <span className="text-gray-500">Method:</span>
                  <code className="ml-2 text-gray-800">GET</code>
                </div>
                <div>
                  <span className="text-gray-500">Headers:</span>
                  <pre className="mt-1 text-sm">
                    <code className="text-gray-800">
                      {JSON.stringify({
                        'Authorization': 'Bearer YOUR_API_TOKEN'
                      }, null, 2)}
                    </code>
                  </pre>
                </div>
                <div>
                  <span className="text-gray-500">Query Parameters:</span>
                  <pre className="mt-1 text-sm">
                    <code className="text-gray-800">
                      {JSON.stringify({
                        page: "1 (optional)",
                        limit: "20 (optional)",
                        stage: "deal_stage_id (optional)",
                        search: "search term (optional)"
                      }, null, 2)}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>

          {/* Custom Fields */}
          <div>
            <h4 className="font-medium text-gray-900">Working with Custom Fields</h4>
            <div className="mt-2 space-y-4">
              {/* Get Custom Fields */}
              <div>
                <h5 className="text-sm font-medium text-gray-900">1. List Custom Fields</h5>
                <div className="mt-2 bg-gray-100 p-4 rounded-lg space-y-2">
                  <div>
                    <span className="text-gray-500">URL:</span>
                    <code className="ml-2 text-gray-800">https://api.nextclient.com/v1/custom-fields</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Method:</span>
                    <code className="ml-2 text-gray-800">GET</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Headers:</span>
                    <pre className="mt-1 text-sm">
                      <code className="text-gray-800">
                        {JSON.stringify({
                          'Authorization': 'Bearer YOUR_API_TOKEN'
                        }, null, 2)}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>

              {/* Create Custom Field */}
              <div>
                <h5 className="text-sm font-medium text-gray-900">2. Create Custom Field</h5>
                <div className="mt-2 bg-gray-100 p-4 rounded-lg space-y-2">
                  <div>
                    <span className="text-gray-500">URL:</span>
                    <code className="ml-2 text-gray-800">https://api.nextclient.com/v1/custom-fields</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Method:</span>
                    <code className="ml-2 text-gray-800">POST</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Headers:</span>
                    <pre className="mt-1 text-sm">
                      <code className="text-gray-800">
                        {JSON.stringify({
                          'Authorization': 'Bearer YOUR_API_TOKEN',
                          'Content-Type': 'application/json'
                        }, null, 2)}
                      </code>
                    </pre>
                  </div>
                  <div>
                    <span className="text-gray-500">Body Example:</span>
                    <pre className="mt-1 text-sm">
                      <code className="text-gray-800">
                        {JSON.stringify({
                          name: "Field Name",
                          type: "text | number | date | select",
                          options: ["Option 1", "Option 2"], // Only for select type
                          required: false
                        }, null, 2)}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>

              {/* Update Deal with Custom Field */}
              <div>
                <h5 className="text-sm font-medium text-gray-900">3. Update Deal with Custom Field</h5>
                <div className="mt-2 bg-gray-100 p-4 rounded-lg space-y-2">
                  <div>
                    <span className="text-gray-500">URL:</span>
                    <code className="ml-2 text-gray-800">https://api.nextclient.com/v1/deals/:id</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Method:</span>
                    <code className="ml-2 text-gray-800">PUT</code>
                  </div>
                  <div>
                    <span className="text-gray-500">Headers:</span>
                    <pre className="mt-1 text-sm">
                      <code className="text-gray-800">
                        {JSON.stringify({
                          'Authorization': 'Bearer YOUR_API_TOKEN',
                          'Content-Type': 'application/json'
                        }, null, 2)}
                      </code>
                    </pre>
                  </div>
                  <div>
                    <span className="text-gray-500">Body Example:</span>
                    <pre className="mt-1 text-sm">
                      <code className="text-gray-800">
                        {JSON.stringify({
                          customFields: [
                            { fieldId: "custom_field_id", value: "field value" }
                          ]
                        }, null, 2)}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* API Documentation Link */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">API Documentation</h3>
        <p className="text-gray-600 mb-4">
          View our comprehensive API documentation to learn how to integrate with our API.
        </p>
        <NavLink
          to="/settings/api-docs"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          View API Documentation
          <FiArrowRight className="ml-2" />
        </NavLink>
      </div>
    </div>
  );
};

export default IntegrationsTab;
