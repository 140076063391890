import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string | React.ReactNode;
}

const faqs: FAQItem[] = [
  {
    question: 'What is a CRM in sales?',
    answer: 'A CRM, or sales CRM tool, is a system sales teams use to manage customer data and nurture customer relationships. Several popular CRM and sales tools exist on the market, including NextClient CRM.'
  },
  {
    question: 'How do I migrate to NextClient CRM?',
    answer: 'You can comfortably switch to NextClient CRM from any popular CRM service–even spreadsheets. You can also avail our Jumpstart service that includes everything from requirement gathering to setup and onboarding. We\'ll help you get started in no time.'
  },
  {
    question: 'How flexible are the contracts?',
    answer: 'You are not bound by any contracts or commitments. NextClient CRM is a pay-as-you-go service (monthly or yearly), so you can make changes to your plan whenever you need to, and upgrade or downgrade at will.'
  },
  {
    question: 'How does CRM help sales?',
    answer: 'A CRM for sales enables you and your reps to centralize contact information, track ongoing activities and boost customer retention. NextClient CRM\'s visual sales pipeline lets you see your entire sales cycle at a glance, highlighting key opportunities. Get notified when you need to follow up, helping you enhance your customer experience and drive more deals than ever.'
  },
  {
    question: 'How does NextClient CRM work?',
    answer: (
      <div>
        In a nutshell, NextClient CRM enables sales teams in small businesses to:
        <ul className="mt-2 ml-6 list-disc space-y-1">
          <li>Streamline processes and consolidate sales data in one unified CRM sales tool.</li>
          <li>Automate follow-ups and ensure timely responses to leads.</li>
          <li>Keep an eye on sales achievements and assess team performance for ongoing improvement.</li>
        </ul>
      </div>
    )
  }
];

const FAQItem: React.FC<{ faq: FAQItem }> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-700">
      <button
        className="flex w-full items-center justify-between py-6 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-white">{faq.question}</span>
        <ChevronDown
          className={`h-5 w-5 text-gray-400 transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="pb-6">
          <div className="text-gray-400">{faq.answer}</div>
        </div>
      )}
    </div>
  );
};

export const FAQSection: React.FC = () => {
  return (
    <div className="bg-navy-900 py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-center text-3xl font-bold text-white sm:text-4xl">
            Frequently asked questions
          </h2>
          <div className="mt-12">
            {faqs.map((faq, index) => (
              <FAQItem key={index} faq={faq} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
