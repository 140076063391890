import { create } from 'zustand';
import { db } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  deleteDoc, 
  doc, 
  onSnapshot, 
  query, 
  where, 
  updateDoc, 
  serverTimestamp, 
  writeBatch,
  orderBy 
} from 'firebase/firestore';
import { useAuthStore } from './authStore';
import type { CustomField, CustomFieldType } from '../types/customField';

interface CustomFieldsState {
  fields: CustomField[];
  loading: boolean;
  error: string | null;
  addField: (field: Omit<CustomField, 'id' | 'createdAt' | 'organizationId'>) => Promise<void>;
  deleteField: (fieldId: string) => Promise<void>;
  updateField: (fieldId: string, updates: Partial<Omit<CustomField, 'id'>>) => Promise<void>;
  reorderFields: (newOrder: string[]) => Promise<void>;
  subscribeToFields: () => () => void;
}

export const useCustomFieldsStore = create<CustomFieldsState>((set, get) => ({
  fields: [],
  loading: false,
  error: null,

  subscribeToFields: () => {
    const user = useAuthStore.getState().user;
    console.log('Subscribing to Custom Fields - User:', user);

    if (!user?.organizationId) {
      console.error('No organization ID found in user data:', user);
      return () => {};
    }

    const q = query(
      collection(db, 'customFields'),
      where('organizationId', '==', user.organizationId),
      orderBy('order', 'asc')
    );

    console.log('Custom Fields Query:', {
      collection: 'customFields',
      organizationId: user.organizationId
    });

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        console.log('Custom Fields Snapshot:', {
          size: snapshot.size,
          docs: snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        });

        const fields = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CustomField[];
        
        console.log('Processed Custom Fields:', fields);
        set({ fields });
      },
      (error) => {
        console.error('Error in custom fields subscription:', error);
        set({ error: error.message });
      }
    );

    return unsubscribe;
  },

  addField: async (fieldData) => {
    try {
      set({ loading: true, error: null });
      
      const user = useAuthStore.getState().user;
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      const name = fieldData.name?.trim();
      if (!name) {
        throw new Error('Field name is required');
      }

      // Validate field type
      if (!['text', 'number', 'date', 'select', 'url'].includes(fieldData.type)) {
        throw new Error('Invalid field type');
      }

      // Check for duplicate field names
      const existingField = get().fields.find(f => 
        f.name.toLowerCase() === name.toLowerCase()
      );
      if (existingField) {
        throw new Error('A field with this name already exists');
      }

      // Get the current highest order
      const currentFields = get().fields;
      const maxOrder = currentFields.length > 0 
        ? Math.max(...currentFields.map(f => f.order || 0))
        : -1;

      // Create the base field data
      const newField: Omit<CustomField, 'id'> = {
        name,
        type: fieldData.type,
        required: Boolean(fieldData.required),
        organizationId: user.organizationId,
        createdAt: serverTimestamp(),
        order: maxOrder + 1,
        displayLocation: fieldData.displayLocation || 'general'
      };

      // Only add options if it's a select field and options are provided
      if (fieldData.type === 'select' && fieldData.options) {
        const options = fieldData.options
          .map(opt => opt.trim())
          .filter(opt => opt.length > 0);
        
        if (options.length === 0) {
          throw new Error('Select fields must have at least one valid option');
        }
        
        newField.options = options;
      }

      await addDoc(collection(db, 'customFields'), newField);
      set({ loading: false });
    } catch (error: any) {
      console.error('Error adding custom field:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  updateField: async (fieldId, updates) => {
    try {
      set({ loading: true, error: null });
      
      // Prepare the update object
      const updateData: Record<string, any> = { 
        ...updates, 
        updatedAt: serverTimestamp(),
        // Ensure displayLocation is always set
        displayLocation: updates.displayLocation || 'general'
      };
      
      // Explicitly handle options field
      if (updates.type === 'select') {
        // Ensure options exist and are valid for select fields
        if (updates.options && updates.options.length > 0) {
          updateData.options = updates.options;
        }
      } else {
        // For non-select fields, remove options field completely
        delete updateData.options;
      }

      const fieldRef = doc(db, 'customFields', fieldId);
      await updateDoc(fieldRef, updateData);
      
      // Update local state
      set(state => ({
        fields: state.fields.map(field => 
          field.id === fieldId 
            ? { 
                ...field, 
                ...updates,
                displayLocation: updateData.displayLocation,
                options: updateData.options 
              } 
            : field
        )
      }));
    } catch (error: any) {
      console.error('Error updating field:', error);
      set({ error: error.message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  reorderFields: async (newOrder) => {
    try {
      set({ loading: true, error: null });
      
      // Get current fields
      const currentFields = get().fields;
      
      // Create batch for updating order
      const batch = writeBatch(db);
      
      // Update each field's order in Firestore
      newOrder.forEach((fieldId, index) => {
        const fieldRef = doc(db, 'customFields', fieldId);
        batch.update(fieldRef, { order: index });
      });
      
      await batch.commit();
      
      // Update local state
      set(state => ({
        fields: newOrder.map(fieldId => 
          currentFields.find(f => f.id === fieldId)!
        )
      }));
    } catch (error: any) {
      set({ error: error.message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deleteField: async (fieldId: string) => {
    try {
      set({ loading: true, error: null });
      await deleteDoc(doc(db, 'customFields', fieldId));
      set({ loading: false });
    } catch (error: any) {
      console.error('Error deleting custom field:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },
}));